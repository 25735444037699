<template>
  <modal-paper
    :show="show"
    @cancel="$emit('cancel')"
  >
    <div class="p-5">
      <p>
        New Game
      </p>
      <div>
        <div>
          <form-label>Rows</form-label>
          <form-input
            v-model:value="newGame.rows"
            type="number"
          />
        </div>
        <div>
          <form-label>Columns</form-label>
          <form-input
            v-model:value="newGame.columns"
            type="number"
          />
        </div>
      </div>

      <!-- TODO: yes, I have to refactor all this code bellow :) -->
      <div
        style="display:flex;margin:10px 0;align-items:center;justify-content:space-between;width:100%;"
        :style="{backgroundColor: newGame.players[0].color }"
      >
        <div>
          P1 =>
          <input
            v-model="newGame.players[0].type"
            value="ai"
            type="radio"
            name="p1_type"
          >CPU
          <input
            v-model="newGame.players[0].type"
            value="human"
            type="radio"
            name="p1_type"
          >Human
          <input
            v-model="newGame.players[0].type"
            value="none"
            type="radio"
            name="p1_type"
          >none
        </div>
        <div v-show="newGame.players[0].type === 'ai'">
          A.I. =>
          <input
            v-model="newGame.players[0].aiLevel"
            value="1"
            type="radio"
            name="p1_ailevel"
          >1
          <input
            v-model="newGame.players[0].aiLevel"
            value="2"
            type="radio"
            name="p1_ailevel"
          >2
          <input
            v-model="newGame.players[0].aiLevel"
            value="3"
            type="radio"
            name="p1_ailevel"
          >3
        </div>
      </div>

      <div
        style="display:flex;margin:10px 0;align-items:center;justify-content:space-between;width:100%;"
        :style="{backgroundColor: newGame.players[1].color }"
      >
        <div>
          P2 =>
          <input
            v-model="newGame.players[1].type"
            value="ai"
            type="radio"
            name="p2_type"
          >CPU
          <input
            v-model="newGame.players[1].type"
            value="human"
            type="radio"
            name="p2_type"
          >Human
          <input
            v-model="newGame.players[1].type"
            value="none"
            type="radio"
            name="p2_type"
          >none
        </div>
        <div v-show="newGame.players[1].type === 'ai'">
          A.I. =>
          <input
            v-model="newGame.players[1].aiLevel"
            value="1"
            type="radio"
            name="p2_ailevel"
          >1
          <input
            v-model="newGame.players[1].aiLevel"
            value="2"
            type="radio"
            name="p2_ailevel"
          >2
          <input
            v-model="newGame.players[1].aiLevel"
            value="3"
            type="radio"
            name="p2_ailevel"
          >3
        </div>
      </div>

      <div
        style="display:flex;margin:10px 0;align-items:center;justify-content:space-between;width:100%;"
        :style="{backgroundColor: newGame.players[2].color }"
      >
        <div>
          P3 =>
          <input
            v-model="newGame.players[2].type"
            value="ai"
            type="radio"
            name="p3_type"
          >CPU
          <input
            v-model="newGame.players[2].type"
            value="human"
            type="radio"
            name="p3_type"
          >Human
          <input
            v-model="newGame.players[2].type"
            value="none"
            type="radio"
            name="p3_type"
          >none
        </div>
        <div v-show="newGame.players[2].type === 'ai'">
          A.I. =>
          <input
            v-model="newGame.players[2].aiLevel"
            value="1"
            type="radio"
            name="p3_ailevel"
          >1
          <input
            v-model="newGame.players[2].aiLevel"
            value="2"
            type="radio"
            name="p3_ailevel"
          >2
          <input
            v-model="newGame.players[2].aiLevel"
            value="3"
            type="radio"
            name="p3_ailevel"
          >3
        </div>
      </div>

      <div
        style="display:flex;margin:10px 0;align-items:center;justify-content:space-between;width:100%;"
        :style="{backgroundColor: newGame.players[3].color }"
      >
        <div>
          P3 =>
          <input
            v-model="newGame.players[3].type"
            value="ai"
            type="radio"
            name="p4_type"
          >CPU
          <input
            v-model="newGame.players[3].type"
            value="human"
            type="radio"
            name="p4_type"
          >Human
          <input
            v-model="newGame.players[3].type"
            value="none"
            type="radio"
            name="p4_type"
          >none
        </div>
        <div v-show="newGame.players[3].type === 'ai'">
          A.I. =>
          <input
            v-model="newGame.players[3].aiLevel"
            value="1"
            type="radio"
            name="p4_ailevel"
          >1
          <input
            v-model="newGame.players[3].aiLevel"
            value="2"
            type="radio"
            name="p4_ailevel"
          >2
          <input
            v-model="newGame.players[3].aiLevel"
            value="3"
            type="radio"
            name="p4_ailevel"
          >3
        </div>
      </div>
      <!-- yes, I have to refactor all the code above :) -->

      <div>
        <form-button
          @click="$emit('confirm', newGameFiltered)"
        >
          Confirm
        </form-button>
      </div>
    </div>
  </modal-paper>
</template>

<script>
import FormButton from '../form/FormButton'
import FormInput from '../form/FormInput'
import FormLabel from '../form/FormLabel.vue'
import ModalPaper from './ModalPaper'

const COLORS = {
  blue: '#BBDEFB',
  green: '#C8E6C9',
  red: '#FFCDD2',
  yellow: '#FFF9C4'
}

export default {
  name: 'ModalNewGame',

  components: {
    ModalPaper,
    FormButton,
    FormInput,
    FormLabel
  },

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  emits: {
    cancel: null,
    confirm: (newGame) => {
      return typeof (newGame) === 'object'
    }
  },

  data () {
    return {
      newGame: {
        rows: 5,
        columns: 5,
        players: [
          { name: 'p1', color: COLORS.blue, type: 'human', aiLevel: 3 },
          { name: 'p2', color: COLORS.green, type: 'ai', aiLevel: 3 },
          { name: 'p3', color: COLORS.red, type: 'none', aiLevel: 3 },
          { name: 'p4', color: COLORS.yellow, type: 'none', aiLevel: 3 }
        ]
      }
    }
  },

  computed: {
    // TODO: refactor this
    newGameFiltered () {
      var newGame = {
        rows: this.newGame.rows,
        columns: this.newGame.columns,
        players: []
      }

      for (let i = 0; i < this.newGame.players.length; i++) {
        if (this.newGame.players[i].type !== 'none') {
          newGame.players.push(this.newGame.players[i])
        }
      }

      return newGame
    }
  }
}
</script>
