<template>
  <transition name="fade">
    <div
      v-show="show"
      class="modal"
    >
      <div
        class="modal__layer"
        @click="emitCancelEvent"
      />
      <div class="modal__box">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
const KEY_ESC = 27

export default {
  name: 'ModalPaper',

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  emits: {
    cancel: null
  },

  watch: {
    show (newValue, oldValue) {
      if (newValue) {
        document.addEventListener('keyup', this.checkKeyUp)
      } else {
        document.removeEventListener('keyup', this.checkKeyUp)
      }
    }
  },

  methods: {
    checkKeyUp (event) {
      if (event.keyCode === KEY_ESC) {
        this.emitCancelEvent()
      }
    },

    emitCancelEvent (event) {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  &__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.3;
  }

  &__box {
    position: relative;
    margin: 40px auto;
    width: 400px;
    height: 350px;
    background: #fff;
    border-radius: 2px;

    &::before, &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      width: 40%;
      height: 10px;
      box-shadow: 0 10px 14px rgba(0,0,0,.7);
      z-index: -1;
      transition: all .3s ease-in-out;
    }

    &::before {
      left: 15px;
      transform: skew(-5deg) rotate(-5deg);
    }

    &::after {
      right: 15px;
      transform: skew(5deg) rotate(5deg);
    }
  }
}
</style>
