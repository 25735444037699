<template>
  <div class="play">
    <background-paper>
      <transition name="fade">
        <game-grid
          v-if="showGameGrid"
          :game="game"
          @line-clicked="lineClicked"
          @bar-clicked="barClicked"
        />
      </transition>

      <form-button
        @click="newGame"
      >
        New Game
      </form-button>
    </background-paper>

    <modal-new-game
      :show="showNewGameModal"
      @confirm="createNewGame"
      @cancel="hideNewGameModal"
    >
      New Game
    </modal-new-game>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GameGrid from '../components/GameGrid'
import BackgroundPaper from '../components/BackgroundPaper'
import ModalNewGame from '../components/modals/ModalNewGame.vue'
import FormButton from '../components/form/FormButton.vue'

export default {
  name: 'PlayPage',

  components: {
    GameGrid,
    BackgroundPaper,
    ModalNewGame,
    FormButton
  },

  data () {
    return {
      showNewGameModal: false,
      showGameGrid: false
    }
  },

  computed: {
    ...mapGetters('game', ['game'])
  },

  mounted () {
    if (this.game.status === null) {
      this.newGame()
    }
  },

  methods: {
    ...mapActions('game', ['createGame', 'setSideClicked']),

    lineClicked (lineId) {
      this.setSideClicked(lineId)
    },

    barClicked (barId) {
      this.setSideClicked(barId)
    },

    newGame () {
      this.showNewGameModal = true
    },

    hideNewGameModal () {
      this.showNewGameModal = false
    },

    createNewGame (newGame) {
      this.showGameGrid = false
      this.createGame(newGame)
      this.showNewGameModal = false
      setInterval(() => {
        this.showGameGrid = true
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.play {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
</style>
