<template>
  <div
    class="cell-piece"
    :style="{ backgroundColor: color }"
  />
</template>

<script>
export default {
  name: 'CellPiece',

  props: {
    color: {
      type: String,
      required: false,
      default: 'none'
    }
  }
}
</script>

<style lang="scss" scoped>
  .cell-piece {
    width: 100%;
    height: 100%;
    transition: background-color 0.7s ease;
  }
</style>
