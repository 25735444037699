<template>
  <div class="dot-piece" />
</template>

<script>
export default {
  name: 'DotPiece'
}
</script>

<style lang="scss" scoped>
  .dot-piece {
    background-color: var(--dot-color);
    border-radius: 50%;
  }
</style>
