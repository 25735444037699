<template>
  <input
    :type="type"
    :value="value"
    @input="changed"
  >
</template>

<script>
export default {
  name: 'FormInput',

  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },

    value: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },

  emits: {
    'update:value': (inputValue) => {
      return typeof (inputValue)
    }
  },

  methods: {
    changed (event) {
      this.$emit('update:value', event.target.value)
    }
  }
}
</script>
