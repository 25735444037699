<template>
  <div class="game__grid">
    <div
      v-for="row in numberOfRows"
      :key="`grid-row-${row}`"
      class="game__row-grid"
    >
      <div class="game__row-container">
        <div
          v-for="column in numberOfColumns"
          :key="`row-lines-${column}`"
          class="game__row-lines"
        >
          <dot-piece class="game__dot" />
          <line-piece
            v-if="column < numberOfColumns.length - 1"
            class="game__line"
            :clicked="isLineClicked(row, column)"
            :dotted-color="activePlayer.color"
            @line-clicked="lineClicked(row, column)"
          /><!-- the last loop is only the last dot -->
        </div>
      </div>

      <div
        v-if="row < numberOfRows.length - 1"
        class="game__row-container"
      >
        <!-- the last row don't print bar and cells -->
        <div
          v-for="column in numberOfColumns"
          :key="`row-bars-${column}`"
          class="game__row-bars"
        >
          <bar-piece
            class="game__bar"
            :clicked="isBarClicked(row, column)"
            :dotted-color="activePlayer.color"
            @bar-clicked="barClicked(row, column)"
          />
          <cell-piece
            v-if="column < numberOfColumns.length - 1"
            class="game__cell"
            :color="getCellColor(row, column)"
          /> <!-- the last loop is only the last bar -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLineName, getBarName, getCellName } from '../store/modules/game/mutations'
import LinePiece from './LinePiece.vue'
import BarPiece from './BarPiece.vue'
import DotPiece from './DotPiece'
import CellPiece from './CellPiece'

export default {
  name: 'GameGrid',

  components: {
    LinePiece,
    BarPiece,
    DotPiece,
    CellPiece
  },

  props: {
    game: {
      type: Object,
      required: true
    }
  },

  emits: {
    lineClicked: (lineName) => {
      return typeof (lineName) === 'string'
    },
    barClicked: (barName) => {
      return typeof (barName) === 'string'
    }
  },

  computed: {
    activePlayer () {
      return this.game.players[this.game.activePlayer]
    },

    numberOfRows () {
      var rows = []
      for (var i = 0; i <= this.game.numberOfRows; i++) {
        rows.push(i)
      }
      return rows
    },

    numberOfColumns () {
      var columns = []
      for (var i = 0; i <= this.game.numberOfColumns; i++) {
        columns.push(i)
      }
      return columns
    }
  },

  methods: {
    isLineClicked (row, column) {
      return this.game.sideGrid[getLineName(row, column)].clickedBy !== null
    },

    isBarClicked (row, column) {
      return this.game.sideGrid[getBarName(row, column)].clickedBy !== null
    },

    getCellColor (row, column) {
      var cell = this.game.cellGrid[getCellName(row, column)]
      if (cell.ownedBy !== null) {
        return this.game.players[cell.ownedBy].color
      }
      return 'none'
    },

    lineClicked (gridRow, gridColumn) {
      this.$emit('lineClicked', getLineName(gridRow, gridColumn))
    },

    barClicked (gridRow, gridColumn) {
      this.$emit('barClicked', getBarName(gridRow, gridColumn))
    }
  }
}
</script>

<style lang="scss" scoped>
.game {
  &__grid {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__row-container {
    display: flex;
  }

  &__row-bars {
    display: flex;
    align-items: center;
  }

  &__row-lines {
    display: flex;
    align-items: center;
  }

  &__cell {
    width: var(--cell-size);
    height: var(--cell-size);
  }

  &__line {
    width: var(--line-width);
    height: var(--line-height);
  }

  &__bar {
    width: var(--bar-width);
    height: var(--bar-height);
  }

  &__dot {
    width: var(--dot-size);
    height: var(--dot-size);
  }
}
</style>
