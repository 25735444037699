<template>
  <div
    class="line-piece"
    @click="$emit('lineClicked')"
  >
    <div
      v-if="!clicked"
      class="line-piece__click-area"
    />
    <div
      v-if="!clicked"
      class="line-piece__line-dotted"
      :style="{ borderColor: dottedColor }"
    />
    <div
      class="line-piece__line"
      :class="{ 'line-piece__line--full': clicked}"
    />
  </div>
</template>

<script>
export default {
  name: 'LinePiece',

  props: {
    clicked: {
      type: Boolean,
      required: true
    },
    dottedColor: {
      type: String,
      required: false,
      default: '#000000'
    }
  },

  emits: {
    lineClicked: null
  }
}
</script>

<style lang="scss" scoped>
  .line-piece {
    display: flex;
    align-items: center;
    position: relative;

    &__line {
      position: absolute;
      width: 0;
      left: 0;
      height: 10%;
      background-color: var(--line-color);
      transition: width .5s;

      &--full{
        border: 1px solid var(--line-color);
        width: 100%;
      }
    }

    &__line-dotted {
      position: absolute;
      width: 100%;
      left: 0;
      border-top: 3px solid;
      border-style: dashed;
      margin-top: 2px;
      height: 1px;
      pointer-events: none;
      touch-action: none;
    }

    &__click-area {
      position: absolute;
      width: 100%;
      height: 500%;

      &:hover {
        background-color: rgb(228, 228, 228);
      }
    }
  }
</style>
