<template>
  <label>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'FormLabel'
}
</script>
