<template>
  <div
    class="bar-piece"
    @click="$emit('barClicked')"
  >
    <div
      v-if="!clicked"
      class="bar-piece__click-area"
    />
    <div
      v-if="!clicked"
      class="bar-piece__bar-dotted"
      :style="{ borderColor: dottedColor }"
    />
    <div
      class="bar-piece__bar"
      :class="{ 'bar-piece__bar--full': clicked}"
    />
  </div>
</template>

<script>
export default {
  name: 'BarPiece',

  props: {
    clicked: {
      type: Boolean,
      required: true
    },
    dottedColor: {
      type: String,
      required: false,
      default: '#000000'
    }
  },

  emits: {
    barClicked: null
  }
}
</script>

<style lang="scss" scoped>
  .bar-piece {
    display: flex;
    justify-content: center;
    position: relative;

    &__bar {
      position: absolute;
      top: 0;
      width: 10%;
      height: 0;
      background-color: var(--bar-color);
      transition: height .5s;

      &--full{
        border: 1px solid var(--bar-color);
        height: 100%;
      }
    }

    &__bar-dotted {
      position: absolute;
      top: 0;
      width: 1px;
      border-left: 3px solid;
      border-style: dashed;
      margin-left: 1px;
      height: 100%;
      pointer-events: none;
      touch-action: none;
    }

    &__click-area {
      position: absolute;
      width: 40px;
      height: 100%;

      &:hover {
        background-color: rgb(228, 228, 228);
      }
    }
  }
</style>
